<template>
  <div class="grid admintext-demo">
    <Toolbar class="toolbar_top">
      <template v-slot:start>
        <div class="my-2">
          <h3>{{ t('currentTasks') }}</h3>
        </div>
      </template>
    </Toolbar>
    <div class="col-12">
      <div class="card">
        <DataTable :value="listProcessingTasks"
                   :paginator="true" class="p-datatable-gridlines table-filter-texte"
                   :rows="30" :totalRecords="nbElmentsProcessingTasks" dataKey="id" :rowHover="true"
                   v-model:filters="filtersProcessingTasks" filterDisplay="menu"
                   responsiveLayout="scroll"
                   :globalFilterFields="['action','model','created_at','status','user_name']"
                   :loading="loadingProcessingTasks">
          <template #empty>
            {{ t('NoDataToDisplay') }}
          </template>
          <template #loading>
            {{ t('LoadingDataPleaseWait') }}
          </template>
          <Column field="action" header="Action"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <p>{{data.action}}</p>
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="t('search')"/>
            </template>
          </Column>
          <Column field="model" header="Type"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <p>{{data.model}}</p>
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="t('search')"/>
            </template>
          </Column>
          <Column :header="t('creationDate')" filterField="created_at" dataType="date"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              {{ formatDate(data.created_at) }}
            </template>
            <template #filter="{filterModel}">
              <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
            </template>
          </Column>
          <Column field="status" :header="t('status')"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <p>{{data.status}}</p>
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="t('search')"/>
            </template>
          </Column>
          <Column field="user_name" :header="t('user')"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <p>{{data.user_name}}</p>
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="t('search')"/>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
  <Toast/>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {usePrimeVue} from "primevue/config";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "current_tasks",
  data() {
    return {
      listProcessingTasks: [],
      nbElmentsProcessingTasks:0,
      loadingProcessingTasks: false,
      filtersProcessingTasks: null,
      listSiteByEntity: [],

    }
  },
  setup() {
    const idSite = computed(() => {
      return store.getters.currentSite.id
    });
    const store = useStore()
    const primevue = usePrimeVue();
      const list_site = computed(() => {
          return store.getters.getListSites
      })
    return {store, idSite, t: primevue.config.StoreTranslation.t, list_site}
  },
  watch: {
      list_site(value){
          this.listSiteByEntity = value
          this.getProcessingTasks()
      }
  },
  async mounted() {
    this.loadingProcessingTasks = true
    // await this.getCurrentSites();
      this.listSiteByEntity = this.list_site
      if (this.list_site)
         await this.getProcessingTasks()
  },
  created() {
    this.initFilters1();
  },
  methods: {
    initFilters1() {
      this.filtersProcessingTasks = {
        'action': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'model': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'created_at': {value: null, matchMode: FilterMatchMode.DATE_IS},
        'status': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'user_name': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },
    clearFilter1() {
      this.initFilters1();
    },
    getProcessingTasks() {
      this.loadingProcessingTasks = true
      this.listSiteByEntity.push(this.idSite)
      let sites = this.listSiteByEntity.length>0 ? this.listSiteByEntity : [this.idSite]
      return this.store.dispatch(Actions.GET_PROCESSING_TASKS, {site_id: sites}).then((data) => {
        data.forEach(element=>{
          element.created_at = new Date(element.created_at)
        })
        this.listProcessingTasks = data
        this.nbElmentsProcessingTasks = data.length
        this.loadingProcessingTasks = false
      })
    },
    formatDate(value) {
      if(value){
        return new Date(value).toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      }
      else {
        return '';
      }
    },
    getCurrentSites() {
      this.listSiteByEntity = []
      let exist = false
      let objectCurrentSite = []
      this.treeSelectNodes = []
      return this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {'customHeader': false}).then(data => {
        this.listSitesValid = true
        var formatSelectTree = (obj) => {
          obj?.forEach((element) => {
            if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
              formatSelectTree(element.childrens)
            }
            Object.assign(element, {"children": element.childrens})
            if (Object.prototype.hasOwnProperty.call(element, 'site_name')) {
              Object.assign(element, {"label": element.site_name})
              element.id = element.site_id
            } else {
              Object.assign(element, {"label": element.name})
            }
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"key": element.id})
          });
          return obj;
        };
        this.treeSelectNodes = formatSelectTree(data);

        function getObjectCurrentSite(arr, id_site) {
          arr?.forEach((obj) => {
            if (obj.childrens && !exist) {
              getObjectCurrentSite(obj.childrens, id_site, obj);
            }
            if (obj.childrens && obj.id == id_site) {
              exist = true
              objectCurrentSite = obj
              return objectCurrentSite
            }

          });
          return objectCurrentSite
        }

        objectCurrentSite = getObjectCurrentSite(this.treeSelectNodes, this.idSite)
        const getSitesTree = (objectCurrentSite) => {
          if (objectCurrentSite.childrens) {
            objectCurrentSite.childrens.forEach(element => {
              if (element.entity_type === 'site') {
                this.listSiteByEntity.push(element.id);
              } else {
                getSitesTree(element);
              }
            });
          }
        };
        getSitesTree(objectCurrentSite);
      })
    },

  }
}
</script>

<style scoped>

</style>